"use client"

import React from "react"
import { ReactQueryProvider } from "@/providers/react-query-provider"
import { ThemeProvider } from "@/providers/theme-provider"
import { RouteProvider } from "@/providers/route-provider"
import { SearchProvider } from "@/providers/search-provider"
import { composeProviders } from "@/providers/composeProviders"
import { type ThemeProviderProps } from "next-themes/dist/types"
import { ToastProvider } from "@radix-ui/react-toast"

type ProviderWithProps<P> = React.ComponentType<P & { children?: React.ReactNode }>

export const withProps = <P extends object>(
  Provider: ProviderWithProps<P>,
  props: Omit<P, "children">,
) => {
  const WrappedProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Provider {...(props as P)}>{children}</Provider>
  )
  WrappedProvider.displayName = `withProps(${Provider.displayName || Provider.name || "Component"})`
  return WrappedProvider
}

const providers: React.ComponentType<{ children: React.ReactNode }>[] = [
  ReactQueryProvider,
  withProps<ThemeProviderProps>(ThemeProvider, {
    attribute: "class",
    defaultTheme: "dark",
    disableTransitionOnChange: true,
    enableSystem: true,
  }),
  RouteProvider,
  SearchProvider,
  ToastProvider,
]

export const AppProviders = composeProviders(providers)
