import React from "react"

type ProviderProps = {
  children: React.ReactNode
}

type Provider = React.ComponentType<ProviderProps>

export function composeProviders(providers: Provider[]): React.ComponentType<ProviderProps> {
  return ({ children }: ProviderProps) => {
    return providers.reduceRight((acc, Provider) => {
      return <Provider>{acc}</Provider>
    }, children)
  }
}
