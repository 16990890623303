"use client"
import { createContext, useContext, Dispatch, SetStateAction, useState, ReactNode } from "react"

const SearchContext = createContext({
  searchQuery: "",
  setSearchQuery: Function as unknown as Dispatch<SetStateAction<string>>,
})

export const useSearchContext = () => useContext(SearchContext)

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const [searchQuery, setSearchQuery] = useState("")

  return (
    <SearchContext.Provider value={{ searchQuery, setSearchQuery }}>
      {children}
    </SearchContext.Provider>
  )
}
