import(/* webpackMode: "eager", webpackExports: ["ToastViewport"] */ "/webapp/node_modules/@radix-ui/react-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/webapp/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/webapp/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/webapp/src/components/auth-debug-session.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/webapp/src/providers/app-provider.tsx");
;
import(/* webpackMode: "eager" */ "/webapp/src/styles/globals.css");
