"use client"

import { IAuthSession } from "@/types/auth"
import { useSearchParams } from "next/navigation"

export default function AuthDebugSession({ session }: { session: IAuthSession | null }) {
  const searchParams = useSearchParams()
  const debug = Boolean(searchParams.get("debug"))
  if (!debug) return null
  return (
    <div className="container flex flex-col rounded-md bg-gray-600 p-0">
      <div className="rounded-t-md bg-gray-500 px-4 py-2 font-bold">
        {`[Debug Mode] Current Session`}
      </div>
      <pre className="whitespace-pre-wrap break-all px-4 py-6">
        {JSON.stringify(session, null, 2)}
      </pre>
    </div>
  )
}
