"use client"

import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from "react"

interface RouteContextProps {
  previousRoute: string
  setPreviousRoute: Dispatch<SetStateAction<string>>
}

interface RouteProviderProps {
  children: ReactNode
  initialPreviousRoute?: string
}

const RouteContext = createContext<RouteContextProps | undefined>(undefined)

export const RouteProvider = ({ children, initialPreviousRoute = "/" }: RouteProviderProps) => {
  const [previousRoute, setPreviousRoute] = useState<string>(initialPreviousRoute)

  return (
    <RouteContext.Provider value={{ previousRoute, setPreviousRoute }}>
      {children}
    </RouteContext.Provider>
  )
}

export const useRoute = () => {
  const context = useContext(RouteContext)
  if (!context) {
    throw new Error("useRoute must be used within a RouteProvider")
  }
  return context
}
